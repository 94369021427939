<template>
     <div class="container">
        
        <img src="../assets/psglogo.png" class="logo" />

        <form @submit.prevent="handleSubmit">
          <div class="group">
            <div class="areacode" style="text-align: right;">
              <div class="codevwrap">
                <span >+86</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QkRGODE1MTI4NDlFMTFFOUI4MUNGMEI0RTc1QzlFNTMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QkRGODE1MTM4NDlFMTFFOUI4MUNGMEI0RTc1QzlFNTMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBMTZCMDk1RDg0OUUxMUU5QjgxQ0YwQjRFNzVDOUU1MyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBMTZCMDk1RTg0OUUxMUU5QjgxQ0YwQjRFNzVDOUU1MyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PguOqVcAAAGDSURBVHja7JbPKwRhGMd30NIeyI1y9z9IivEjkVLIRQ5ycFKKg6IoN+UkJ7lwc0AOe5lSDg5biEjbEMK2rbCXvY7vW9/VcxhjfuzYaJ76tO/z7s589v0x7zOaZVmxckRFrEwRiSNxaFFVbOi6/itCwzBsR9wG1sFECV0tYBQ02o6YsQfq2W4GcwGly2CB7Sxo+G6NTdGeBYsBpPNCquLFaXONgEeRL4EZH9JpsCLyBzDoJL5X+ww8ib5VMOVBOgnWRK7u1UG54+NkUp5hrnHDjbuQjoENXlOUtoM7t89xGnSCnJBvgmEH6RDYApXMMxyA6fUAueaFr8zVDbdBv81v+8COkGY5vWm/J9cl6AbvzONgl7PxdfawL848x76boEfmGegBeebVYB+0kgNQI6TqT125PjJ/iBToBUlQCxLgkN8l+PnG2bkodZE44foWmNcRFR+gC5yHVZ2OuZEKoi/PkZ6GXRaPwAB4Brdc/5Tvsui1uoGmP/kioEXv1ZH434k/BRgAj4hL0Xi1WTEAAAAASUVORK5CYII="
                />
              </div>
            </div>
            <input type="tel" v-model="phone" placeholder="请输入手机号" maxlength="11" class="item"  style="padding-left: .4rem;"/>
          </div>


          <div class="group">
            <span class=""  style="width: 1.84rem; text-align: right;">密码:</span>
            <input type="password"
              v-model="password" 
              placeholder="请输入密码"
              class="item"
              style="padding-left: .4rem;"
            />
          </div>

          <div v-if="!isLogin" class="group">
            <span class="" style="width: 1.84rem; text-align: right;">确认密码:</span>
            <input type="password"
              v-model="confirmPassword" 
              placeholder="请再次输入密码"
              class="item"
              style="padding-left: .4rem;"
            />
          </div>


          <button type="submit" class="btn submit" style="margin-top: .8rem;">{{ isLogin ? '登录' : '注册' }}</button>
          <div style="margin-top:0.4rem;text-align: right;padding-right:0.4rem;">
            <a @click.prevent="toggleMode" style="font-size: 0.4rem;text-decoration: none;color: #999;" href="#">{{ isLogin ? '注册' : '已有账号点此登录' }}</a>
          </div>
        </form>
    </div>
</template>

<script>


export default {
  data() {
    return {
      isLogin: true,
      phone: '',
      password: '',
      confirmPassword: '',
    };
  },
  methods: {
    handleSubmit() {
      if (this.phone==''){
            this.$createToast({
            txt: '手机号码不能空',
            type: 'txt',
          }).show();
          return;
      }
      if (this.password==''){
            this.$createToast({
            txt: '密码不能',
            type: 'txt',
          }).show();
          return;
      }

      if (this.isLogin) {
        // 处理登录逻辑
        this.handleLogin();
      } else {
        // 处理注册逻辑
        if (this.password !== this.confirmPassword) {
          this.$createToast({
            txt: '两次输入密码不相同',
            type: 'txt',
          }).show();
          return;
        }
        this.handleReg();
 
      }
    },
    toggleMode() {
      // 切换登录/注册模式
      this.isLogin = !this.isLogin;
    },

    async handleLogin() {
      try {
        // 发送登录请求
        const response = await this.$axios.post('/login/', {
          phone: this.phone,
          password: this.password,
        });

        //{"success": true,"data": {其他数据}, "message": "登录成功"}
        if (response.data.result==1) {
          // 登录成功，跳转到 /a 页面
          // this.$createToast({
          //   type: 'txt',
          //   txt: '登录成功！',
          // }).show();

          window.location.href = '/psg/#/identity'

        } else {
          // 登录失败，弹出提示  //{"success": false,"data": {其他数据}, "message": "手机号未注册<br>请检查手机号，或请先注册!"}
          this.$createToast({
            type: 'warn',
            txt: response.data.msg?response.data.msg:'手机号未注册<br>请检查手机号，或请先注册!',
          }).show();
          
        }
      } catch (error) {
        console.error('登录请求出错', error);
      }
    },
    async handleReg() {
      try {
        // 发送登录请求
        const response = await this.$axios.post('/reg/', {
          phone: this.phone,
          password: this.password,
        });

        //{"success": true,"data": {其他数据}, "message": "登录成功"}
        if (response.data.result==1) {
          // 登录成功，跳转到 /a 页面
          // this.$createToast({
          //   type: 'txt',
          //   txt: '注册成功',
          // }).show();
          window.location.href = '/psg/#/identity'
          

        } else {
          this.$createToast({
            type: 'warn',
            txt: response.data.msg?response.data.msg:'该号码已注册<br>请切换到登录!',
          }).show();
          
        }
      } catch (error) {
        console.error('注册请求出错', error);
      }
    },

  },
};
</script>