import Vue from 'vue'

import {
  Toast
} from 'cube-ui'
Vue.use(Toast)

import axios from 'axios'
Vue.prototype.$axios = axios
// axios.defaults.baseURL = '/api'


import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')

