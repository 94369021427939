<template>
  <div id="app">
    <my-top></my-top>
    <my-login></my-login>
    <my-footer></my-footer>
  </div>
</template>

<script>
import MyTop from './components/myTop.vue'
import MyLogin from './components/myLogin.vue'
import MyFooter from './components/myFooter.vue'
export default {
  name: 'App',
  components: {
    MyTop,
    MyLogin,
    MyFooter,
  }
}
</script>

<style>
@import "./assets/login.css";
</style>
